import { Button, Errors, FieldDescription, Form, Heading, Input, Label, Section, TextField } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { createEffect, createSignal, Match, Show, Switch } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { useUser } from '../../providers/user';
import { gql, mutationAction, useMutation } from '../../graphql';
import { SharedPasswordEntry } from '../../partials/auth/flow/shared-password-entry';
import { RequestPhoneNumber } from '../../components/request-phone-number';

export default function Profile() {
	const user = useUser();
	const changeNameAction = useMutation(changeNameMutation);
	const updateEmailAction = useMutation(updateEmailMutation);
	const confirmUpdateEmailAction = useMutation(confirmUpdateEmailMutation);
	const updatePasswordAction = useMutation(updatePasswordMutation);
	const [newEmail, setNewEmail] = createSignal(user()!.me.email);

	const changeNameSubmission = useSubmission(changeNameAction);
	const updateEmailSubmission = useSubmission(updateEmailAction);
	const confirmEmailSubmission = useSubmission(confirmUpdateEmailAction);
	const updatePasswordSubmission = useSubmission(updatePasswordAction);

	createEffect(() => {
		if (confirmEmailSubmission.result?.data?.confirmUpdateEmail.email) {
			updateEmailSubmission.clear();
		}
		if (updateEmailSubmission.pending) {
			confirmEmailSubmission.clear();
		}
	});

	const [isValidPasswordUpdate, setIsValidPasswordUpdate] = createSignal(false);

	return (
		<>
			<Title>Profile | My account | Troon</Title>
			<Heading as="h1">Profile</Heading>
			<Form
				action={changeNameAction}
				document={changeName}
				class="flex flex-col gap-4 rounded border border-neutral p-6"
			>
				<Heading as="h2" size="h3" id="update-name">
					Update Name
				</Heading>
				<div class="flex w-full flex-row flex-wrap gap-4">
					<TextField name="firstName" class="grow">
						<Label>First name</Label>
						<Input value={user()!.me.firstName} />
					</TextField>

					<TextField name="lastName" class="grow">
						<Label>Last name</Label>
						<Input value={user()!.me.lastName} />
					</TextField>
				</div>

				<Errors />

				<Show when={changeNameSubmission.result?.data?.updateName}>
					<p class="flex items-center gap-2 rounded bg-brand-100 px-4 py-1 text-brand-600">
						<IconCircleCheck /> Your name has been successfully updated.
					</p>
				</Show>

				<Button type="submit" class="size-fit">
					Save
				</Button>
			</Form>

			<Switch>
				<Match when={updateEmailSubmission.result?.data?.updateEmail.ok}>
					<Form
						action={confirmUpdateEmailAction}
						document={confirmUpdateEmail}
						class="flex flex-col gap-4 rounded border border-neutral p-6"
					>
						<Heading as="h2" size="h3" id="update-email">
							Update Email Address
						</Heading>
						<TextField name="newEmail" class="grow">
							<Label>Email address</Label>
							<Input inputMode="email" readonly value={newEmail()} />
						</TextField>

						<TextField name="code" class="grow">
							<Label>Confirmation Code</Label>
							<Input />
							<FieldDescription>
								Please check your inbox for a confirmation code and enter it here to continue updating your email
								address.
							</FieldDescription>
						</TextField>

						<Errors />

						<Button type="submit" class="size-fit">
							Confirm
						</Button>
					</Form>
				</Match>
				<Match when>
					<Form
						action={updateEmailAction}
						document={updateEmail}
						class="flex flex-col gap-4 rounded border border-neutral p-6"
					>
						<Heading as="h2" size="h3" id="update-email">
							Update Email Address
						</Heading>
						<TextField name="newEmail" class="grow">
							<Label>Email address</Label>
							<Input inputMode="email" value={user()!.me.email} onInput={(e) => setNewEmail(e.target.value)} />
						</TextField>

						<Errors />

						<Show when={confirmEmailSubmission.result?.data?.confirmUpdateEmail}>
							<p class="flex items-center gap-2 rounded bg-brand-100 px-4 py-1 text-brand-600">
								<IconCircleCheck /> Your email address has been successfully updated.
							</p>
						</Show>

						<Button type="submit" class="size-fit">
							Update
						</Button>
					</Form>
				</Match>
			</Switch>

			<Section class="rounded border border-neutral p-6">
				<Heading as="h2" size="h3">
					Update Phone Number
				</Heading>
				<RequestPhoneNumber buttonLabel="Update" buttonClass="size-fit grow-0 shrink" />
			</Section>

			<Form
				action={updatePasswordAction}
				document={updatePassword}
				class="flex flex-col gap-4 rounded border border-neutral p-6"
			>
				<Heading as="h2" size="h3" id="update-password">
					Update Password
				</Heading>

				<TextField name="oldPassword">
					<Label suppressRequired>Current Password</Label>
					<Input type="password" />
				</TextField>

				<SharedPasswordEntry
					onUpdate={setIsValidPasswordUpdate}
					passwordLabel="New Password"
					confirmLabel="Confirm New Password"
				/>

				<Errors />

				<Show when={updatePasswordSubmission.result?.data?.changePassword.ok}>
					<p class="flex items-center gap-2 rounded bg-brand-100 px-4 py-1 text-brand-600">
						<IconCircleCheck /> Your password has been updated.
					</p>
				</Show>

				<Button type="submit" class="size-fit" disabled={!isValidPasswordUpdate()}>
					Update password
				</Button>
			</Form>
		</>
	);
}

const changeName = gql(`mutation updateName($firstName: String!, $lastName: String!) {
	updateName(firstName: $firstName, lastName: $lastName) {
		firstName
		lastName
	}
}`);
const changeNameMutation = mutationAction(changeName, {
	revalidates: ['loggedInUser'],
});

const updateEmail = gql(`mutation updateEmail($newEmail: String!) {
	updateEmail(input: { newEmail: $newEmail }) { ok }
}`);

const updateEmailMutation = mutationAction(updateEmail, {});

const confirmUpdateEmail = gql(`mutation confirmUpdateEmail($newEmail: String!, $code: String!) {
	confirmUpdateEmail(input: { newEmail: $newEmail, confirmationCode: $code }) { email }
}`);

const confirmUpdateEmailMutation = mutationAction(confirmUpdateEmail, {
	revalidates: ['loggedInUser'],
});

const updatePassword = gql(`mutation updatePassword($oldPassword: String!, $password: String!) {
	changePassword(input: { oldPassword: $oldPassword, newPassword: $password }) { ok }
}`);
const updatePasswordMutation = mutationAction(updatePassword, {});
